import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Studijní materiály Hydrologie a hydrogeografie" />
    <h1>Studijní materiály Hydrologie a hydrogeografie</h1>
    <p>Níže jsou sepsány&nbsp;on-line učební materiály <strong>z <strong>předmětu Z0059 Hydrologie</strong></strong> na brněnské geografii&nbsp;na MUNI z podzimního semestru 2018. Jde o přepsané zápisky z přednášek doplněné o poznatky z doporučených skript&nbsp;<em><a href="https://is.muni.cz/publication/674320">Klimageografia a&nbsp;hydrogeografia</a></em> od Milana Trizny. Ve studijních materiálech nejsou obsažena všechna témata, která byla probrána na přednáškách.</p>
    <p><em>(Kdyby se Vám náhodou chtělo, můžete mě podpořit lajkem na <a href="https://www.facebook.com/zemepisec">Fejsbůku</a>)</em></p>
    <hr />
    <ImgCon><Hydro /><div><h2>Co je hydrologie? A co hydrogeografie?</h2>
    <p>Podle zjednodušené definice je <strong>hydrologie</strong> věda, která zkoumá <strong>zákonitosti</strong> výskytu, oběhu, časového a prostorového rozdělení vody na Zemi, její <strong>vzájemné působení s prostředím</strong> a její <strong>veškeré vlastnosti</strong>. <strong>Definice</strong> <strong>hydrogeografie</strong> hovoří o tom, že hydrogeografie je věda zaobírající se <strong>vztahy mezi vodními útvary na pevnině</strong> <strong>a ostatními krajinotvornými prvky</strong>.</p>
    <p><em>(podrobněji v kapitole <Link to="/hydrologie/uvod-do-studia/">Úvod do studia hydrologie a hydrogeografie</Link>)</em></p></div></ImgCon>
    <hr />
    <h2>Hydrologická a hydrogeografická témata studijních materiálů</h2>
<ul><li><Link to="/hydrologie/uvod-do-studia/">Úvod do studia hydrologie a hydrogeografie</Link></li><li><Link to="/hydrologie/hydrologicky-cyklus/">Hydrologický cyklus</Link></li><li><Link to="/hydrologie/protipovodnova-opatreni/">Protipovodňová opatření</Link></li><li><Link to="/hydrologie/podpovrchova-voda/">Podpovrchová voda</Link></li><li><Link to="/hydrologie/prameny-mineralni-vody/">Prameny a minerální voda</Link></li><li><Link to="/hydrologie/vodni-nadrze/">Vodní nádrže</Link></li><li><Link to="/hydrologie/mokrady/">Mokřady</Link></li><li><Link to="/hydrologie/svetovy-ocean/">Světový oceán</Link></li><li><Link to="/hydrologie/cleneni-svetoveho-oceanu/">Členění světového oceánu</Link></li></ul>
  </Layout>
)
